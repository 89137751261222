import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, } from "../../../customComponent/CustomAlertMessage";
import UserList from "../UserList";
import FundsTransferHistory from "../FundsTransferHistory";
import DepositList from "../DepositList";
import WithdrawalList from "../WithdrawalList";
import UserStackingHistory from "../UserStackingHistory";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const HomePage = () => {
  const myPermission = sessionStorage.getItem("permissions");
  let permissions = Array.isArray(JSON.parse(myPermission)) ? JSON.parse(myPermission)?.map(x => x.value) : [];
  const userType = sessionStorage.getItem("admin_type");



  const [activeScreen, setActiveScreen] = useState("dashboard");
  const [totalUser, setTotalUser] = useState("");
  const [totalStacking, setTotalStacking] = useState("");
  const [totalDeposit, setTotalDeposit] = useState("");
  const [totalWithdrawal, setTotalWithdrawal] = useState("");

  useEffect(() => {
    totaluserData();

  }, []);

  const totaluserData = async () => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.dashboard();
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        setTotalUser(result?.data?.totalUsers);
        setTotalDeposit(result?.data?.totalDeposit);
        setTotalStacking(result?.data?.totalStakingAmount);
        setTotalWithdrawal(result?.data?.totalWithdrawal);
        // alertSuccessMessage(result?.message)
      } else {
        alertErrorMessage(result?.message);
      }
    }
    finally { LoaderHelper.loaderStatus(false) };
  };



  return activeScreen === "dashboard" ? (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="fa fa-th"></i>
                      </div>
                      Dashboard
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="container-xl px-4 mt-n10">
            <div className="row">
              {permissions.includes(1) || userType === '1' ? (
                <div className="col-lg-6 col-xl-4 mb-4">
                  <div className="card text-light bg-primary border border-primary-subtle rounded-3 h-100">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-3">
                          <div className="text-white-75">Total Users</div>
                          <div className="display-5 fw-bold">{totalUser}</div>
                        </div>
                        <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                      </div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-between small">
                      <Link
                        className="text-white stretched-link"
                        to="/user_list"
                        style={{ cursor: "pointer" }}
                        onClick={() => setActiveScreen("totaluser")}
                      >
                        View All
                      </Link>
                      <div className="text-white">
                        <i className="fas fa-angle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {userType === "1" ? (
                <>
                  <div className="col-lg-6 col-xl-4 mb-4">
                    <div className="card text-light bg-dark border border-dark-subtle rounded-3  h-100">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="me-3">
                            <div className="text-white-75 ">
                              Total Deposit
                            </div>
                            <div className="display-5 fw-bold">
                              {totalDeposit}
                            </div>
                          </div>
                          <i className="feather-xl text-white-50 fa fa-user-plus"></i>
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between small">
                        <Link
                          className="text-white stretched-link"
                          to="/funds_deposit"
                          style={{ cursor: "pointer" }}
                          onClick={() => setActiveScreen("totalDeposit")}
                        >
                          View All
                        </Link>
                        <div className="text-white">
                          <i className="fas fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {permissions.includes(2) || userType === "1" ? (
                <div className="col-lg-6 col-xl-4 mb-4">
                  <div className="card text-light bg-success border border-success-subtle rounded-3 h-100">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-3">
                          <div className="text-white-75 ">User Stacking Amount</div>
                          <div className="display-5 fw-bold">
                            {totalStacking}
                          </div>
                        </div>
                        <i className="feather-xl text-white-50 fas fa-headset "></i>
                      </div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-between small">
                      <Link
                        className="text-white stretched-link"
                        to="/user_stackingHistory"
                        style={{ cursor: "pointer" }}
                        onClick={() => setActiveScreen("stackingPackage")}
                      >
                        View All
                      </Link>
                      <div className="text-white">
                        <i className="fas fa-angle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row">
              {permissions.includes(4) || userType === "1" ? (
                <>
                  <div className="col-lg-6 col-xl-4 mb-4">
                    <div className="card text-light bg-danger border border-danger-subtle rounded-3 h-100">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="me-3">
                            <div className="text-white-75 ">
                              Total Withdrawal
                            </div>
                            <div className="display-5 fw-bold">
                              {totalWithdrawal}
                            </div>
                          </div>
                          <i className="feather-xl text-white-50 fa fa fa-wallet"></i>
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between small">
                        <Link
                          className="text-white stretched-link"
                          to="/funds_withdrawal"
                          style={{ cursor: "pointer" }}

                        >
                          View All
                        </Link>
                        <div className="text-white">
                          <i className="fas fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </main>
      </div>
    </>
  ) : activeScreen === "totaluser" ? (
    <UserList />
  ) : activeScreen === "stackingPackage" ? (
    <UserStackingHistory />
  ) : activeScreen === "userList" ? (
    <UserList />
  ) : activeScreen === "totalWithdraw" ? (
    <FundsTransferHistory />
  ) : activeScreen === "totalDeposit" ? (
    <DepositList />
  ) : activeScreen === "todayWithdrawalRequest" ? (
    <WithdrawalList />
  ) : undefined;
};
export default HomePage;
