import React, { useState, useEffect } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertSuccessMessage, alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTable from "react-data-table-component";

const Notification = () => {


    const [notificationTitle, setNotificationTitle] = useState('');
    const [notification, setNotification] = useState('');
    const [notificationList, setNotificationList] = useState('');
   

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "notificationTitle":
                setNotificationTitle(event.target.value);
                break;
            case "notification":
                setNotification(event.target.value);
                break;
            default:
        };
    };


    const handleAddNotify = async (title, message) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.addNotify(title, message);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                setNotificationTitle('');
                setNotification('');
                handleGetNotification();
            } else {
                alertErrorMessage(result?.success)
            };
        }
        finally { LoaderHelper.loaderStatus(false) };
    }


    useEffect(() => {
        handleGetNotification()
    }, []);

    const handleGetNotification = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.getNotificationList()
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                // alertSuccessMessage();
                let filterData = result?.data.reverse().map((item, index) => ({ ...item, index: index + 1, id: index + 1 }))
                setNotificationList(filterData);
            } else {
                alertErrorMessage(result?.message);
            };
        }
        finally { LoaderHelper.loaderStatus(false) }
    }

    const columns = [
        { name: 'Sr No.', selector: (row, index) => row?.index, sort: true, wrap: true },
        { name: 'Title', selector: row => row?.title, wrap: true, sort: true },
        { name: 'Message', selector: row => row?.message, wrap: true, sort: true },
        { name: 'Actions', selector: row => (<><button type="button" title="Delete" className="btn btn-danger btn-sm" onClick={() => handleDelete(row?._id)}><i className="fa-solid fa-trash"></i></button></>), wrap: true, sort: true },
    ]

    const handleDelete = async (_id) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.deleteNotify(_id);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                handleGetNotification();
            } else {
                alertErrorMessage(result?.message)
            };
        }
        finally { LoaderHelper.loaderStatus(false) };
    }

    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="fa fa-bell"></i></div>
                                            Notifications
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="row" >
                            <div className="col-xl-4">
                                <div className="card mb-4 mb-xl-0">
                                    <div className="card-body d-flex justify-content-center flex-column p-5 ">
                                        <div className="d-flex align-items-center justify-content-start mb-4 ">
                                            <h5 className="mb-0" >Send Notification</h5>
                                        </div>
                                        <form>
                                            <div className="form-group  mb-3" >
                                                <label className="small mb-1"> Notification Title </label>
                                                <input className="form-control  form-control-solid" type="text" placeholder="Enter Title" name="notificationTitle" value={notificationTitle} onChange={handleInputChange}></input>
                                            </div>
                                            <div className="form-group  mb-3" >
                                                <label className="small mb-1"> Notification </label>
                                                <textarea className="form-control form-control-solid" rows={6} name="notification" value={notification} onChange={handleInputChange}></textarea>
                                            </div>
                                            <button className="btn btn-primary btn-block w-100 mt-2" type="button" onClick={() => handleAddNotify(notificationTitle, notification)} disabled={!notificationTitle} > Send Notification </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8" >
                                <div className="card">
                                    {notificationList.length === 0 ? "" :
                                        <div className="card-header">Notification List
                                            <div className="dropdown">
                                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                    <CSVLink data={notificationList} className="dropdown-item">Expoprt as CSV</CSVLink>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="card-body" >
                                        <form className="row" >
                                            <div className="col-12" >
                                                <div className="" width="100%" >
                                                    {notificationList.length === 0 ? <h6 className="ifnoData"><img src="assets/img/no-data.png" /> <br /> No Data Available</h6> :

                                                        <DataTable columns={columns} data={notificationList} pagination />}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default Notification;