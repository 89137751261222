import { ApiConfig } from "../apiConfig/ApiConfig";
import { ConsoleLogs } from "../../utils/ConsoleLogs";
import { ApiCallDelete, ApiCallPost, ApiCallPut } from "../apiConfig/ApiCall";
import { ApiCallGET } from "../apiConfig/ApiCall";
const TAG = "AuthService";

const AuthService = {

  login: async (email, password) => {
    const { verifyadmin, login } = ApiConfig;
    const url = verifyadmin + login;
    const params = {
      email: email,
      password: password,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  dashboard: async () => {
    const { verifyadmin, dashboard } = ApiConfig;
    const url = verifyadmin + dashboard;
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallGET(url, headers);
  },

  addSubAdmin: async (email, password, admin_type, permissions, name) => {
    const { verifyadmin, addSubAdmin } = ApiConfig;
    const url = verifyadmin + addSubAdmin;
    const params = {
      email: email,
      password: password,
      admin_type: admin_type,
      permissions: permissions,
      name: name,
    };
    ConsoleLogs(TAG + ", addSubAdmin", `url : + ${url}`);
    ConsoleLogs(
      TAG + ", addSubAdmin",
      `loginRequestParams : '+ ${JSON.stringify(params)}'`
    );
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  getusers: async () => {
    const { verifyadmin, getuserlist } = ApiConfig;
    const url = verifyadmin + getuserlist;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGET(url, headers);
  },



  getSubAdminList: async () => {
    const { verifyadmin, subadminlist } = ApiConfig;
    const url = verifyadmin + subadminlist;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGET(url, headers);
  },

  submitUpdateSubadmin: async (subadminId, name, email, permissions) => {
    const { verifyadmin, updateSubadmin } = ApiConfig;
    const url = verifyadmin + updateSubadmin;
    const params = {
      id: subadminId,
      name: name,
      email: email,
      permissions: permissions,
    };
    ConsoleLogs(TAG + ", updateSubadminList", `url : ' + ${url}`);
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPut(url, params, headers);
  },

  deleteSubAdminList: async (_id) => {
    const { verifyadmin, subadmindelete } = ApiConfig;
    const url = `${verifyadmin}${subadmindelete}/${_id} `;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallDelete(url, headers);
  },

  handleSubadminStatus: async (userId, status) => {
    const { verifyadmin, SubadminStatus } = ApiConfig;
    const url = verifyadmin + SubadminStatus;
    const params = {
      _id: userId,
      status: status,
    };
    ConsoleLogs(TAG + ", BannerStatus", `url : + ${url}`);
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },



  handleUserListStatus: async (user_id, status) => {
    const { verifyadmin, setuserstatus } = ApiConfig;
    const url = verifyadmin + setuserstatus;
    const params = {
      user_id: user_id,
      status: status,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  allUsersData: async (User_ID) => {
    const { verifyadmin, getDetails } = ApiConfig;
    const url = `${verifyadmin}${getDetails}?user_id=${User_ID}`;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGET(url, headers);
  },

  updateUserRank: async (id, rank) => {
    const { verifyadmin, updateUserRank } = ApiConfig;
    const url = verifyadmin + updateUserRank;
    const params = {
      id: id,
      rank: rank
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPut(url, params, headers);
  },


  adminFundsHistory: async () => {
    const { verifyadmin, adminFundsHistory } = ApiConfig;
    const url = verifyadmin + adminFundsHistory;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGET(url, headers);
  },

  getStackingHistory: async () => {
    const { stackingDetails, stackinghistory } = ApiConfig;
    const url = stackingDetails + stackinghistory;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGET(url, headers);
  },


  adminStackingHistory: async () => {
    const { verifyadmin, adminStackingHistory } = ApiConfig;
    const url = verifyadmin + adminStackingHistory;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGET(url, headers);
  },

  addNotify: async (title, message) => {
    const { verifyadmin, addNotify } = ApiConfig;
    const url = verifyadmin + addNotify;
    const params = {
      title: title,
      message: message,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getNotificationList: async () => {
    const { verifyadmin, notificationList } = ApiConfig;
    const url = verifyadmin + notificationList;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGET(url, headers);
  },


  deleteNotify: async (_id) => {
    const { verifyadmin, deleteNotify } = ApiConfig;
    const url = `${verifyadmin}/${deleteNotify}/${_id}`;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallDelete(url, headers);
  },




  // getLevels: async (userId) => {
  //   const { verifyadmin, userLevels } = ApiConfig;
  //   const url = verifyadmin + userLevels;
  //   const params = {
  //     loginid: userId,
  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };
  //   return ApiCallPost(url, params, headers);
  // },


  addFunds: async (amount, user_id) => {
    const { verifyadmin, addFunds } = ApiConfig;
    const url = verifyadmin + addFunds;
    const params = {
      user_id: user_id,
      amount: amount,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  fundsDeduct: async (amount, user_id) => {
    const { verifyadmin, deductFunds } = ApiConfig;
    const url = verifyadmin + deductFunds;
    const params = {
      user_id: user_id,
      amount: amount,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  fundsAddStake: async (user_id, amount, phase) => {
    const { verifyadmin, fundsAddStake } = ApiConfig;
    const url = verifyadmin + fundsAddStake;
    const params = {
      user_id: user_id,
      amount: amount,
      phase: phase,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  fundsDeductStake: async (user_id, amount, phase) => {
    const { verifyadmin, fundsDeductStake } = ApiConfig;
    const url = verifyadmin + fundsDeductStake;
    const params = {
      user_id: user_id,
      amount: amount,
      phase: phase,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  getWithdrawalList: async (status) => {
    const { verifyadmin, withdrawalList } = ApiConfig;
    const url = verifyadmin + withdrawalList;
    const params = {
      status: status,

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  withdrawalStatus: async (_id, status, transaction_hash) => {
    const { verifyadmin, withdrawalStatus } = ApiConfig;
    const url = verifyadmin + withdrawalStatus;
    const params = {
      _id: _id,
      status: status,
      transaction_hash: transaction_hash,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getDepositList: async (status) => {
    const { verifyadmin, depositList } = ApiConfig;
    const url = verifyadmin + depositList;
    const params = {
      status: status
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  depositStatus: async (_id, status) => {
    const { verifyadmin, depositStatus } = ApiConfig;
    const url = verifyadmin + depositStatus;
    const params = {
      _id: _id,
      status: status,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getNewsList: async () => {
    const { verifyadmin, getNewsList } = ApiConfig;
    const url = verifyadmin + getNewsList;
    const headers = {
      "Content-Type": "application/json",
    }
    return ApiCallGET(url, headers);
  },

  addClassicNews: async (FormData) => {
    const { verifyadmin, addClassicNews } = ApiConfig;
    const url = verifyadmin + addClassicNews;
    const headers = {
      "Content-Type": "multipart/json",
    };
    return ApiCallPost(url, FormData, headers);
  },

  deleteClassicNews: async (id) => {
    const { verifyadmin, deleteClassicNews } = ApiConfig;
    const url = `${verifyadmin}${deleteClassicNews}/${id}`
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallDelete(url, headers)
  },

  getBackupHistory: async () => {
    const { verifyadmin, backupHistory } = ApiConfig;
    const url = verifyadmin + backupHistory;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGET(url, headers)
  }


};

export default AuthService;
