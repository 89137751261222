import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import DataTable from "react-data-table-component";

const UserStackingHistory = () => {

  const [stackingHistory, setStackingHistory] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [calculateStack, setCalculateStack] = useState(0);


  const calculateTotalStackingAmount = (data) => {
    if (!data || data.length === 0) {
      setCalculateStack(0);
      return;
    }

    if (!fromDate || !toDate) {
      const totalAmount = data.reduce((total, item) => total + item.amount, 0);
      setCalculateStack(totalAmount);
      return;
    }

    const filtered = data.filter((item) => {
      const itemDate = moment(item.createdAt).format('YYYY-MM-DD');
      return itemDate >= fromDate && itemDate <= toDate;
    });

    const totalAmount = filtered.reduce((total, item) => total + item.amount, 0);
    setCalculateStack(totalAmount);
  };

  const filterDate = () => {
    calculateTotalStackingAmount(stackingHistory);
  };

  const resetFilterDate = () => {
    setFromDate('');
    setToDate('');
    setCalculateStack(0);
  };

  const columns = [
    { name: 'Sr No.', selector: (row, index) => row?.index, sort: true, wrap: true },
    { name: 'Created At', selector: row => moment(row?.createdAt).format('DD/MM/YY || LT'), sort: true, wrap: true },
    { name: 'Name', selector: row => row?.user_name, sort: true, wrap: true },
    { name: 'User ID', selector: row => row?.user_id, sort: true, wrap: true },
    { name: 'Currency', selector: row => row?.currency, sort: true, wrap: true },
    { name: 'Status', selector: row => row?.status, sort: true, wrap: true },
    { name: <div>Deduct Amount</div>, selector: row => row?.deduct_amount, sort: true, wrap: true },
    { name: <div>Stacking Amount</div>, selector: row => row?.amount, sort: true, wrap: true },
    { name: 'Total', selector: row => row?.total, sort: true, wrap: true },
  ];


  useEffect(() => {
    handleStackingHistory();
  }, []);

  const handleStackingHistory = async () => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.getStackingHistory();
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        let filteredData = result?.data?.reverse().map((item, index) => ({ ...item, index: index + 1, id: index + 1 }))
        setStackingHistory(filteredData);
        setFilterData(filteredData);
      }
    }
    finally { LoaderHelper.loaderStatus(false) };
  };


  const searchObjects = (e) => {

    const keysToSearch = ["user_name", "phase", "currency", "amount", "user_id", "status", "total"];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = filterData.filter(obj => {
      return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
    });
    setStackingHistory(matchingObjects);
  }


  const totalStackingAmount = stackingHistory.map(item => item.amount).reduce((a, b) => a + b, 0)



  return (
    <>
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="d-flex justify-content-between mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-chart-bar"></i>
                    </div>
                    User Stacking History
                  </h1>
                  <span className="me-5 text-grey">Total Stacking Amount : {totalStackingAmount}</span>

                </div>
              </div>
            </div>
          </div>
          {calculateStack === 0 ? null :
            <div className="d-flex justify-content-end me-5">
              <span className="me-5">Stacking Amount : {calculateStack}</span>
            </div>
          }
          <div className="filter_bar">
            <form className="row">
              <div className="ms-5 mb-3 col-md-2">
                <input
                  type="date"
                  className="form-control form-control-solid"
                  data-provide="datepicker"
                  id="litepickerRangePlugin"
                  name="dateFrom"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="ms-5 mb-3 col-md-2">
                <input
                  type="date"
                  className="form-control form-control-solid"
                  data-provide="datepicker"
                  id="litepickerRangePlugin"
                  name="dateTo"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>

              <div className="mb-3 col-md-6">
                <div className="row">
                  <div className="ms-5 mb-3 col-md-3">
                    <button className="btn btn-primary border border-primary-subtle fw-bold btn-block w-100" type="button" onClick={filterDate} disabled={!fromDate || !toDate}>
                      Search
                    </button>
                  </div>
                  <div className=" mb-3 col-md-3">
                    <button className="btn btn-primary border border-primary-subtle fw-bold btn-block w-100" type="button" onClick={resetFilterDate}>
                      Reset
                    </button>

                  </div>
                </div>

              </div>
            </form>
          </div>

        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header">
              <input className="form-control w-50" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
              <div className="d-flex align-items-center">


                <div className="dropdown">
                  <button
                    className="btn btn-dark btn-sm dropdown-toggle"
                    id="dropdownFadeInUp"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Export{" "}
                  </button>
                  <div
                    className="dropdown-menu animated--fade-in-up"
                    aria-labelledby="dropdownFadeInUp"
                  >
                    <CSVLink data={stackingHistory} className="dropdown-item">
                      Export as CSV
                    </CSVLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body mt-3">
              <div className="table-responsive">
                {stackingHistory.length === 0 ? (
                  <h6 className="ifnoData">
                    <img src="assets/img/no-data.png" /> <br /> No Data
                    Available
                  </h6>
                ) : (
                  <DataTable columns={columns} data={stackingHistory} pagination />

                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    </>
  );
};

export default UserStackingHistory;
