import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import { CSVLink } from "react-csv";
import { $ } from 'react-jquery-plugin';
import moment from "moment";
import Select from "react-select";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTable from "react-data-table-component";


const SubAdmin = () => {

    const [subAdminList, setSubAdminList] = useState([]);
    const [subadminId, setSubadminId] = useState([]);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [multipleSelectd, setMultipleSelectd] = useState([]);
    const [admin_type, setAdmin_type] = useState();
    const [filterData, setFilterData] = useState([]);



    const handleSubadmin = async () => {
        LoaderHelper.loaderStatus(true)
        try {
            const result = await AuthService.getSubAdminList();
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                let filterData = result?.data.reverse().map((item, index) => ({ ...item, index: index + 1, id: index + 1 }))
                setFilterData(filterData);
                setSubAdminList(filterData);
                // alertSuccessMessage(result?.message);
            } else {
                alertErrorMessage(result.message);
            }
        }
        finally { LoaderHelper.loaderStatus(false) }
    };

    useEffect(() => {
        handleSubadmin()
    }, []);

    const columns = [
        { name: 'Sr No.', selector: (row, index) => row?.index, sort: true, wrap: true },
        { name: "Created At", sort: true, wrap: true, selector: row => moment(row?.createdAt).format('DD/MM/YY') },
        { name: "Email", sort: true, wrap: true, selector: row => row?.email },
        { name: "Name", sort: true, wrap: true, selector: row => row?.name },
        {
            name: "Status", sort: true, wrap: false, selector: row => (
                <button
                    className={row?.status === "ACTIVE" ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"}
                    onClick={() => handleStatus(row?._id, row?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE")}>
                    {row?.status}</button>
            ),
        },
        {
            name: "Actions", sort: true, wrap: true, selector: row => (<>
                <button className="btn btn-teal btn-sm me-2" title="Edit"
                    onClick={() => {
                        $('#edit_modal').modal('show');
                        setSubadminId(row?._id);
                        setEmail(row?.email);
                        setFullName(row?.name);
                        setMultipleSelectd(row?.permissions)
                    }}>
                    <i className="fa-solid fa-user-pen"></i>
                </button>

                <button
                    className="btn btn-danger btn-sm" title="Delete"
                    onClick={() => deleteSubAdmin(row?._id)}>
                    <i className="fa-solid fa-trash"></i>
                </button>
            </>),
        },
    ]

    const handleStatus = async (userId, status) => {
        LoaderHelper.loaderStatus(true)
        try {
            const result = await AuthService.handleSubadminStatus(userId, status);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                handleSubadmin();
                alertSuccessMessage(result.message);
            } else {
                alertErrorMessage(result.message);
            }
        }
        finally { LoaderHelper.loaderStatus(false) }
    };

    const deleteSubAdmin = async (_id) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.deleteSubAdminList(_id);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                handleSubadmin();
                alertSuccessMessage(result.message);
            } else {
                alertErrorMessage(result.message)
            }
        }
        finally { LoaderHelper.loaderStatus(false) }
    };



    const submitEditSubadmin = async (subadminId, fullName, email, multipleSelectd) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.submitUpdateSubadmin(subadminId, fullName, email, multipleSelectd);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result?.message);
                $('#edit_modal').modal('hide');
                setFullName('');
                setEmail('');
                setMultipleSelectd('');
                setAdmin_type('');
                handleSubadmin();
            } else {
                alertErrorMessage(result?.message);
            }
        }
        finally { LoaderHelper.loaderStatus(false) };
    };

    const handleAddSubAdmin = async (email, password, admin_type, permissions, name,) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.addSubAdmin(email, password, admin_type, permissions, name,);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result.message);
                resetInputChange();
                handleSubadmin();
            } else {
                alertErrorMessage(result?.message);
            }
        }
        finally { LoaderHelper.loaderStatus(false) };
    }

    var multipleSelect = [
        { value: 1, label: 'Users' },
        { value: 2, label: 'Stacking History' },
        { value: 3, label: 'Comission Report' },
        { value: 4, label: 'Funds Management' },
        { value: 5, label: 'Notifications Manangment' },
    ];

    const resetInputChange = () => {
        setFullName('');
        setEmail('');
        setPassword('');
        setMultipleSelectd('');
    }

    const addNewSubadmin = () => {
        resetInputChange();
        $('#edit_add_modal').modal('show');
    }

    const searchObjects = (e) => {
        const keysToSearch = ["name", "email", "phone", "user_id"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = filterData.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setSubAdminList(matchingObjects);
    };



    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="mt-4 d-flex justify-content-between">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="far fa-user"></i></div>
                                            Sub Admin List
                                        </h1>
                                        <button type="button" className="btn btn-md btn_admin btn-orange fw-bold fs-6 me-5" onClick={() => addNewSubadmin()}>Add New</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* Main page content */}
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className="card-header">
                                <input className="form-control w-50" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                                {subAdminList.length === 0 ? "" :
                                    <div className="dropdown">
                                        <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export </button>
                                        <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                            <CSVLink data={subAdminList} className="dropdown-item">Expoprt as CSV</CSVLink>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="card-body ">
                                <div className="table-responsive">
                                    {subAdminList.length === 0 ? <h6 className="ifnoData"><img src="assets/img/no-data.png" /> <br /> No Data Available</h6> :
                                        <DataTable columns={columns} data={subAdminList} pagination />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {/* sub admin edit Pair modal data */}
            <div className="modal" id="edit_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">
                                Edit Sub Admin Details
                            </h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-4">
                                        <label className="small mb-1" htmlFor="inputFirstName">Name <em>*</em></label>
                                        <input className="form-control  form-control-solid" id="inputFirstName" type="text" placeholder="Enter your Name " value={fullName} onChange={(event) => setFullName(event.target.value)} />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" htmlFor="inputEmailAddress">Email</label>
                                        <input className="form-control form-control-solid" id="inputEmailAddress" type="email" placeholder="Enter your email address" value={email} onChange={(event) => setEmail(event.target.value)} />
                                    </div>
                                </div>

                                <div className="row gx-3 mb-3">
                                    <div className="col-md-6" >
                                        <label className="small mb-1" htmlFor="inputLocation">Permissions</label>
                                        <Select isMulti options={multipleSelect}
                                            onChange={setMultipleSelectd}
                                            value={multipleSelectd}
                                        >
                                        </Select>
                                    </div>
                                </div>
                                <button className="btn btn-primary d-flex w-50" type="button" onClick={() => submitEditSubadmin(subadminId, fullName, email, multipleSelectd)}> Submit Details </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* sub admin edit modal data */}


            {/* Add Subadmin pair modal */}
            <div className="modal" id="edit_add_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">
                                Enter Subadmin Details
                            </h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-4">
                                        <label className="small mb-1" htmlFor="inputFirstName">Name <em>*</em></label>
                                        <input className="form-control  form-control-solid" id="inputFirstName" type="text" placeholder="Enter your Name " value={fullName} onChange={(event) => setFullName(event.target.value)} />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="small mb-1" htmlFor="inputEmailAddress">Email <em>*</em></label>
                                        <input className="form-control form-control-solid" id="inputEmailAddress" type="email" placeholder="Enter your email address" value={email} onChange={(event) => setEmail(event.target.value)} />
                                    </div>
                                </div>

                                <div className="row gx-3 mb-3">
                                    <div className="col-md-4">
                                        <label className="small mb-1" htmlFor="inputLocation">Password <em>*</em></label>
                                        <input className="form-control form-control-solid" id="inputLocation" type="text" placeholder="Enter your Password" name="password" value={password} onChange={(event) => setPassword(event.target.value)} />
                                    </div>
                                    <div className="col-md-6" >
                                        <label className="small mb-1" htmlFor="inputLocation">Permissions <em>*</em></label>
                                        <Select isMulti options={multipleSelect}
                                            onChange={setMultipleSelectd}
                                            value={multipleSelectd}
                                        >
                                        </Select>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center w-75 mt-5">
                                    <button className="btn btn-primary w-50 fs-6" type="button" onClick={() => { handleAddSubAdmin(email, password, admin_type, multipleSelectd, fullName); $('#edit_add_modal').modal('hide'); }}> Submit Details </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* Add Subadmin pair modal */}



        </>

    )
}

export default SubAdmin;