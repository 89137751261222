import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";


const FundsTransferHistory = () => {
    const [fundsTranser, setFundsTranser] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [paginationState, setPaginationState] = useState({ page: 1, pageSize: 10 });
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [calculateStack, setCalculateStack] = useState(0)


    const handleFundsTransfer = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.adminFundsHistory();
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                setFilterData(result?.data.reverse());
                setFundsTranser(result?.data);
                // alertSuccessMessage(result?.message);
            }
            else {
                alertErrorMessage(result?.message);
            }
        }
        finally { LoaderHelper.loaderStatus(false) };
    }

    useEffect(() => {
        handleFundsTransfer();

    }, [])

    const calculateTotalStackingAmount = (data) => {
        if (!data || data.length === 0) {
            setCalculateStack(0);
            return;
        }

        if (!fromDate || !toDate) {
            const totalAmount = data.reduce((total, item) => total + item.amount, 0);
            setCalculateStack(totalAmount);
            return;
        }

        const filtered = data.filter((item) => {
            const itemDate = moment(item.createdAt).format('YYYY-MM-DD');
            return itemDate >= fromDate && itemDate <= toDate;
        });

        const totalAmount = filtered.reduce((total, item) => total + item.amount, 0);
        setCalculateStack(totalAmount);
    };

    const filterDate = () => {
        calculateTotalStackingAmount(fundsTranser);
    };

    const resetFilterDate = () => {
        setFromDate('');
        setToDate('');
        setCalculateStack(0);
    };

    const columns = [
        {
            name: "S.No.", sort: true, wrap: true, selector: (row, index) => (paginationState.page - 1) * paginationState.pageSize + index + 1,
        },
        { name: 'Created At', selector: row => moment(row?.createdAt).format('DD/MM/YY || LT'), sort: true, wrap: true },
        { name: 'Name', selector: row => row?.user_name, sort: true, wrap: true },
        { name: 'User ID', selector: row => row?.user_id, sort: true, wrap: true },
        { name: 'Type', selector: row => row?.type, sort: true, wrap: true },
        { name: 'Amount', selector: row => row?.amount, sort: true, wrap: true },
        {
            name: <div> Previous Balance </div>, selector: row => {
                const prevBal = row?.previous_balance;
                if (prevBal % 1 !== 0) { return prevBal.toFixed(2); }
                return prevBal;
            }, sort: true, wrap: true
        },
        {
            name: 'Final Balance', selector: row => {
                const finalBal = row?.final_balance;
                if (finalBal % 1 !== 0) { return finalBal.toFixed(2); }
                return finalBal;
            }, sort: true, wrap: true
        },
    ]
    const searchObjects = (e) => {
        const keysToSearch = ["user_name", "type", "currency", "amount", "user_id", "final_balance", "previous_balance"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = fundsTranser.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setFilterData(matchingObjects);
    };
    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon">
                                                <i className="fa fa-chart-bar"></i>
                                            </div>
                                            Funds Transfer History
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {calculateStack === 0 ? null :
                            <div className="d-flex justify-content-end me-5">
                                <span className="me-5">Funds Transfer Amount : {calculateStack}</span>
                            </div>
                        }
                        <div className="filter_bar">
                            <form className="row">
                                <div className="ms-5 mb-3 col-md-2">
                                    <input
                                        type="date"
                                        className="form-control form-control-solid"
                                        data-provide="datepicker"
                                        id="litepickerRangePlugin"
                                        name="dateFrom"
                                        value={fromDate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                    />
                                </div>
                                <div className="ms-5 mb-3 col-md-2">
                                    <input
                                        type="date"
                                        className="form-control form-control-solid"
                                        data-provide="datepicker"
                                        id="litepickerRangePlugin"
                                        name="dateTo"
                                        value={toDate}
                                        onChange={(e) => setToDate(e.target.value)}
                                    />
                                </div>

                                <div className="mb-3 col-md-6">
                                    <div className="row">
                                        <div className="ms-5 mb-3 col-md-3">
                                            <button className="btn btn-primary border border-primary-subtle fw-bold btn-block w-100" type="button" onClick={filterDate} disabled={!fromDate || !toDate}>
                                                Search
                                            </button>
                                        </div>
                                        <div className=" mb-3 col-md-3">
                                            <button className="btn btn-primary border border-primary-subtle fw-bold btn-block w-100" type="button" onClick={resetFilterDate}>
                                                Reset
                                            </button>

                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className="card-header">
                                <input className="form-control w-50" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                                <div className="d-flex align-items-center">


                                    <div className="dropdown">
                                        <button
                                            className="btn btn-dark btn-sm dropdown-toggle"
                                            id="dropdownFadeInUp"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Export{" "}
                                        </button>
                                        <div
                                            className="dropdown-menu animated--fade-in-up"
                                            aria-labelledby="dropdownFadeInUp"
                                        >
                                            <CSVLink data={fundsTranser} className="dropdown-item">
                                                Export as CSV
                                            </CSVLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body mt-3">
                                <div className="table-responsive">
                                    {fundsTranser.length === 0 ? (
                                        <h6 className="ifnoData">
                                            <img src="assets/img/no-data.png" /> <br /> No Data
                                            Available
                                        </h6>
                                    ) : (
                                        <DataTable columns={columns} data={filterData} pagination onChangePage={(page) => { setPaginationState(prevState => ({ ...prevState, page })) }} onChangeRowsPerPage={(pageSize) => { setPaginationState(prevState => ({ ...prevState, pageSize })) }} />

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default FundsTransferHistory;
