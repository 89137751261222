import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";

const ClassicNews = () => {
    const [newsTitle, setNewsTitle] = useState('');
    const [newsImg, setNewsImg] = useState('');
    const [newsList, setNewsList] = useState('');


    const getNewsList = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.getNewsList();
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                setNewsList(result?.data);
                // alertSuccessMessage(result?.message);
            }
        } finally { LoaderHelper.loaderStatus(false) }
    }

    useEffect(() => {
        getNewsList();
    }, [])

    const handleAddNews = async (title, news_image) => {
        LoaderHelper.loaderStatus(true);
        try {

            const formData = new FormData();
            formData.append('title', title);
            formData.append('news_image', news_image);

            const result = await AuthService.addClassicNews(formData);
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                getNewsList();
                setNewsTitle('');
                setNewsImg(null);
                alertSuccessMessage(result?.message);
            } else {
                alertErrorMessage(result?.message);
            }

        } finally { LoaderHelper.loaderStatus(false) }
    };

    const handleDelete = async (_id) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.deleteClassicNews(_id);
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                alertSuccessMessage(result?.message);
                getNewsList();
                setNewsImg(null);
            } else {
                alertErrorMessage(result?.message)
            }
        } finally { LoaderHelper.loaderStatus(false) }
    }


    const columns = [
        { name: 'Title', sort: true, wrap: true, selector: row => row?.title },
        {
            name: 'Image', sort: true, wrap: true, selector: row => (
                <img className="w-25" src={ApiConfig?.baseUrl + row?.news_image} />
            )
        },
        { name: 'Actions', sort: true, wrap: true, selector: row => (<><button type="button" title="Delete" className="btn btn-danger btn-sm" onClick={() => handleDelete(row?._id)}><i className="fa-solid fa-trash"></i></button></>) }
    ]



    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="fa fa-bell"></i></div>
                                            Classic News
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="row" >
                            <div className="col-xl-4">
                                <div className="card mb-4 mb-xl-0">
                                    <div className="card-body d-flex justify-content-center flex-column p-5 ">
                                        <div className="d-flex align-items-center justify-content-start mb-4 ">
                                            <h5 className="mb-0" >Add News</h5>
                                        </div>
                                        <form>
                                            <div className="form-group  mb-3" >
                                                <label className="small mb-1"> News Title </label>
                                                <input className="form-control  form-control-solid" type="text" placeholder="Enter Title" name="notificationTitle" value={newsTitle} onChange={(event) => setNewsTitle(event.target.value)}></input>
                                            </div>
                                            <div className="form-group  mb-3" >
                                                <label className="small mb-1">Image </label>
                                                <input className="form-control  form-control-solid" type="file" placeholder="Enter Image" name="notificationTitle" onChange={(event) => setNewsImg(event.target.files[0])}></input>
                                            </div>

                                            <button className="btn btn-primary btn-block w-100 mt-2" type="button" onClick={() => handleAddNews(newsTitle, newsImg)} disabled={!newsTitle || !newsImg} >Create News</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8" >
                                <div className="card">
                                    {''.length === 0 ? "" :
                                        <div className="card-header">Notification List
                                            <div className="dropdown">
                                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                    <CSVLink data={newsList} className="dropdown-item">Export as CSV</CSVLink>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="card-body" >
                                        <form className="row" >
                                            <div className="col-12" >
                                                <div>
                                                    {newsList.length === 0 ? <h6 className="ifnoData"><img src="assets/img/no-data.png" /> <br /> No Data Available</h6> :

                                                        <DataTable columns={columns} data={newsList} pagination />}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default ClassicNews;