import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import { ProfileContext } from "../../../context/ProfileProvider";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const LoginPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [, updateProfileState] = useContext(ProfileContext);

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "email":
                setEmail(event.target.value);
                break;
            case "password":
                setPassword(event.target.value);
                break;
            default:
        }
    }

    const handleLogin = async (email, password) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.login(email, password);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                sessionStorage.setItem("token", result?.data?.token);
                sessionStorage.setItem("email", result?.data?.email);
                sessionStorage.setItem("admin_type", result?.data?.admin_type);
                sessionStorage.setItem("permissions", JSON.stringify(result?.data?.permissions || []));
                alertSuccessMessage(result?.message);
                updateProfileState(result?.data);
                navigate('/dashboard');
            } else {
                alertErrorMessage(result.message);
            }
        }
        finally { LoaderHelper.loaderStatus(false) };
    };

    useEffect(() => {
        const enterToLogin = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleLogin(email, password);
            };
        };
        document.addEventListener('keydown', enterToLogin);
        return () => {
            document.removeEventListener('keydown', enterToLogin);
        };
    });

    return (
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main className="login-card">
                    <div className="container-xl px-4">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                                <div className="card my-5">
                                    <div className="card-body p-5 text-center">
                                        <img src="assets/img/carnival-logo.png" className="img-fluid" alt="" />
                                    </div>
                                    {/* <hr className="my-0" /> */}
                                    <div className="card-body p-5">
                                        <form>
                                            <div className="mb-3">
                                                <label className="text-gray-600 small" for="emailExample">Email address</label>
                                                <input className="form-control form-control-solid" type="email" name="email" placeholder="" aria-label="Email Address" aria-describedby="emailExample" value={email} onChange={handleInputChange} />
                                            </div>

                                            <div className="mb-3">
                                                <label className="text-gray-600 small" for="passwordExample">Password</label>
                                                <input className="form-control form-control-solid" type="password" placeholder="" aria-label="Password" name="password" aria-describedby="passwordExample" value={password} onChange={handleInputChange} />
                                            </div>
                                            <div className="text-center mt-2">
                                                <button type="button" className="btn btn-md  fs-5 w-100 btn_admin mt-2 px-5" style={{ backgroundColor: "#a033fe", color: "white" }}
                                                    onClick={() => handleLogin(email, password)}>
                                                    Login
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default LoginPage;