import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loginpage from "../ui/pages/LoginPage";
import { ProfileContext } from "../context/ProfileProvider";
import { ToastContainer } from "react-toastify";
import Header from "../customComponent/Header";
import Sidebar from "../ui/pages/Sidebar";
import HomePage from "../ui/pages/HomePage";
import SubAdmin from "../ui/pages/SubadminList";
import UserList from "../ui/pages/UserList";
import DepositList from "../ui/pages/DepositList";
import Notification from "../ui/pages/Notification";
import WithdrawalList from "../ui/pages/WithdrawalList";
import FundsTransferHistory from "../ui/pages/FundsTransferHistory";
import UserStackingHistory from "../ui/pages/UserStackingHistory";
import AdminStackingHistory from "../ui/pages/AdminStackingHistory";
import ClassicNews from "../ui/pages/Classic News";
import BackupData from "../ui/pages/BackupData";


const Routing = () => {
    const [profileState] = useContext(ProfileContext)
    const token = sessionStorage.getItem('token');


    return (
        <Router>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover limit={1} theme="light" />
            {profileState.token || token ? <>
                <Header />
                <div id="layoutSidenav">
                    <Sidebar />
                    <Routes>
                        <Route exact path="/dashboard" element={<HomePage />} />
                        <Route exact path="*" element={<HomePage />} />
                        <Route exact path="/subadmin" element={<SubAdmin />} />
                        <Route exact path="/user_list" element={<UserList />} />
                        <Route exact path="/user_stackingHistory" element={<UserStackingHistory />} />
                        <Route exact path="/admin_stackingHistory" element={<AdminStackingHistory />} />
                        <Route exact path="/funds_deposit" element={<DepositList />} />
                        <Route exact path="/funds_transfer" element={<FundsTransferHistory />} />
                        <Route exact path="/funds_withdrawal" element={<WithdrawalList />} />
                        <Route exact path="/notifications_management" element={<Notification />} />
                        <Route exact path="/classic_news" element={<ClassicNews />} />
                        <Route exact path="/user_backup" element={<BackupData />} />


                    </Routes>
                </div>
            </> :
                <>
                    <Routes>
                        <Route exect path="/" element={<Loginpage />}></Route>
                        <Route exect path="/*" element={<Loginpage />}></Route>
                    </Routes>
                </>
            }
        </Router>
    );
}

export default Routing;