import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import Select from "react-select";
import { CSVLink } from "react-csv";
import moment from "moment";
import { $ } from 'react-jquery-plugin';
import DataTable from "react-data-table-component";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import copy from "copy-to-clipboard";

const WithdrawalList = () => {

  const [withdrawalList, setWithdrawalList] = useState([]);
  const [status, setStatus] = useState('');
  const [created, setCreated] = useState('');
  const [userID, setUserID] = useState('');
  const [currency, setCurrency] = useState('');
  const [type, setType] = useState('');
  const [id, setId] = useState('')
  const [transaction_hash, setTransaction_hash] = useState('');
  const [withdrawalAddress, setWithdrawalAddress] = useState('');
  const [chain, setChain] = useState('')


  // console.log('stattus',status)

  const handleWithdrawalList = async (status) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.getWithdrawalList(status);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        let filterData = result?.data.reverse().map((item, index) => ({ ...item, index: index + 1, id: index + 1 }))
        setWithdrawalList(filterData);
        // alertSuccessMessage(result.message);
      } else {
        alertErrorMessage(result?.message);
      }
    }
    finally { LoaderHelper.loaderStatus(false) }
  };


  useEffect(() => {
    handleWithdrawalList(status);
  }, [])



  const multipleSelect = [
    { value: 'Select', label: '' },
    { value: 'PENDING', label: 'Pending' },
    { value: 'REJECTED', label: 'Rejected' },
    { value: 'COMPLETED', label: 'Completed' },
  ]


  const columns = [
    { name: 'Sr No.', selector: (row, index) => row?.index, sort: true, wrap: true },
    { name: 'Created At', selector: row => moment(row?.createdAt).format("DD/MM/YY || LT"), sort: true, wrap: true },
    { name: 'Name', selector: row => row?.user_name, sort: true, wrap: true },
    { name: 'User ID', selector: row => row?.user_id, sort: true, wrap: true },
    { name: 'Currency', selector: row => row?.currency, sort: true, wrap: true },

    { name: 'Amount', selector: row => row?.amount, sort: true, wrap: true },
    {
      name: 'Status', selector: row => row?.status,
      cell: row => (
        <span className="fw-bolder" style={{ color: row.status === 'PENDING' ? 'orange' : row.status === 'REJECTED' ? 'red' : 'green' }}>{row?.status}</span>
      ),
      sort: true, wrap: true
    },
    {
      name: 'Actions', selector: row => (
        <button className="btn btn-dark btn-sm me-2"
          title="View"
          onClick={() => {
            $('#edit_modal').modal('show');
            setCreated(row?.createdAt);
            setCurrency(row?.currency);
            setStatus(row?.status);
            setType(row?.type);
            setUserID(row?.user_id);
            setId(row?._id);
            setTransaction_hash(row?.transaction_hash);
            setWithdrawalAddress(row?.withdrawal_address);
            setChain(row?.chain)
          }}><i className="fa-solid fa-eye"></i>
        </button >
      )
    },
  ];

  const handleWithdrawalStatus = async (_id, status, transaction_hash) => {
    try {
      const result = await AuthService.withdrawalStatus(_id, status, transaction_hash);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        handleWithdrawalList(status);
        $('#edit_modal').modal('hide');
      } else {
        alertErrorMessage(result.message)
      }
    }
    finally { LoaderHelper.loaderStatus(false) }
  }


  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="mt-4 d-flex justify-content-between">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="fa fa-dollar-sign"></i>
                      </div>
                      Withdrawal List
                    </h1>
                    <div>

                      <>
                        <Select options={multipleSelect}
                          onChange={(selectedOptions) => {
                            setStatus(selectedOptions.value);
                            handleWithdrawalList(selectedOptions.value ? selectedOptions.value : selectedOptions)
                          }}
                          value={multipleSelect.find(option => option.value === status)} className="text-dark"></Select>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          {/* Main page content */}
          <div className="container-xl px-4 mt-n10">
            <div className="card mb-4">
              <div className="card-header">
                Withdrawal List
                {withdrawalList.length === 0 ? (
                  ""
                ) : (
                  <div className="dropdown">
                    <button
                      className="btn btn-dark btn-sm dropdown-toggle"
                      id="dropdownFadeInUp"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Export{" "}
                    </button>
                    <div
                      className="dropdown-menu animated--fade-in-up"
                      aria-labelledby="dropdownFadeInUp"
                    >
                      <CSVLink data={withdrawalList} className="dropdown-item">
                        Expoprt as CSV
                      </CSVLink>
                    </div>
                  </div>
                )}
              </div>
              <>
                {withdrawalList.length === 0 ? (
                  <h6 className="ifnoData">
                    <img src="assets/img/no-data.png" /> <br /> No Data Available
                  </h6>
                ) : (
                  <div className="card-body mt-3">
                    <div className="table-responsive">
                      <DataTable columns={columns} data={withdrawalList} pagination />
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </main>
      </div>

      {/* Edit Modal */}

      <div className="modal" id="edit_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog  alert_modal modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Withdrawal Details
              </h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row gx-3 mb-3">
                  <div className="col-md-3">
                    <label className="small mb-1" htmlFor="inputCreated">Created At</label>
                    <input className="form-control  form-control-solid" id="inputCreated" type="text"
                      value={moment(created).format('DD/MM/YY LT')} disabled
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="small mb-1" htmlFor="inputUserID">User ID</label>
                    <input className="form-control form-control-solid" id="inputUserID" type="email"
                      value={userID} disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="inputWithdrawalAddress">Withdrawal Address </label>
                    <div className="input-group">
                      <input className="form-control  form-control-solid" id="inputWithdrawalAddress" type="text"
                        value={withdrawalAddress} disabled
                      /><span className="input-group-text form-control-solid">
                        <i className="fa-solid fa-clipboard" type='button' title="Copy" onClick={() => { copy(withdrawalAddress); alertSuccessMessage('Copied') }}></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row gx-3 mb-3">
                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputCurrency">Currency</label>
                      <input className="form-control form-control-solid" id="inputCurrency" type="email"
                        value={currency} disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputChain">Chain</label>
                      <input className="form-control form-control-solid" id="inputChain" type="email"
                        value={chain} disabled
                      />
                    </div>
                  </div>
                  <div className="row gx-3 mb-3">
                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputType">Type</label>
                      <input className="form-control form-control-solid" id="inputType" type="email"
                        value={type} disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputStatus">Status</label>
                      <input className="form-control form-control-solid" id="inputStatus" type="email"
                        value={status} disabled
                      />
                    </div>
                  </div>
                </div>
                {(status === 'COMPLETED' || status === 'REJECTED') ? <div className={`fw-bolder mt-5 ${status === 'COMPLETED' ? 'text-success' : 'text-danger'}`}>The status is {status}</div> :
                  <>
                    <button className="btn btn-success me-3 mt-3" type="button"
                      onClick={() => {
                        $('#edit_approve_modal').modal('show');
                        $('#edit_modal').modal('hide')
                      }}
                    > Approve
                    </button>
                    <button className="btn btn-danger ms-3 mt-3" type="button"
                      onClick={() => { handleWithdrawalStatus(id, 'REJECTED', transaction_hash); }}
                    > Reject </button>
                  </>
                }
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* EDIT APPROVE MODAL */}

      <div className="modal" id="edit_approve_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog  alert_modal modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Transaction Hash
              </h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row gx-3 mb-3">
                  <div className="col-md-10">
                    <input className="form-control  form-control-solid" id="inputCreated" type="text" placeholder="Enter Transaction Hash"
                      value={transaction_hash} onChange={(e) => setTransaction_hash(e.target.value)}
                    />
                  </div>

                </div>
                <button className="btn btn-success me-3 mt-3" type="button"
                  onClick={() => handleWithdrawalStatus(id, 'COMPLETED', transaction_hash)} disabled={!transaction_hash}
                > Approve </button>

              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default WithdrawalList;
