import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import UserDetails from "../UserDetails";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTable from "react-data-table-component";
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const BackupData = () => {
    const [backupHistory, setBackupHistory] = useState([])
    const [filterData, setFilterData] = useState([]);



    useEffect(() => {
        handleBackupHistory();
    }, []);


    const columns = [
        { name: 'Sr No.', selector: (row, index) => row?.index, sort: true, wrap: true },
        { name: "Activation Date", sort: true, wrap: true, selector: row => row.activation_date ? moment(row?.activation_date).format('DD/MM/YY || LT') : "---" },
        { name: "Registration Date", sort: true, wrap: true, selector: row => moment(row?.registration_date).format('DD/MM/YY || LT') },
        { name: <>First Stacking Date</>, sort: true, wrap: true, selector: row => row?.first_staking_date ? (moment(row?.first_staking_date).format('DD/MM/YY || LT')) : "---" },
        { name: "User ID", sort: true, wrap: true, selector: row => row?.user_id, },
        {
            name: "Total ROI Data", sort: true, wrap: true, selector: row => {
                const totalRoi = row?.total_roi;
                if (totalRoi % 1 !== 0) { return totalRoi.toFixed(2); }
                return totalRoi;
            },
        },
        { name: "Total Stacking", sort: true, wrap: true, selector: row => row?.total_staking, },

    ]

    const handleBackupHistory = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.getBackupHistory();
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                let filterData = result?.data.reverse().map((item, index) => ({ ...item, index: index + 1, id: index + 1 }))
                setFilterData(filterData);
                setBackupHistory(filterData);
                // alertSuccessMessage(result?.message);
            } else {
                alertErrorMessage(result?.message);
            };
        }
        finally { LoaderHelper.loaderStatus(false) };
    };



    const searchObjects = (e) => {
        const keysToSearch = ["name", "email", "phone", "user_id"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = filterData.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setBackupHistory(matchingObjects);
    };

    const exportAsPdf = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [['S.No.', 'Activation Date', 'Registration Date', 'First Stacking Date', 'User ID', 'Total ROI Data', 'Total Stacking']],
            body: backupHistory.map(item => [item.index + 1, item.activation_date ? moment(item.activation_date).format("DD/MM/YY") : "---", moment(item.registration_date).format("DD/MM/YY"), item.first_staking_date ? moment(item.first_staking_date).format("DD/MM/YY") : "---", item.user_id, item.total_roi % 1 !== 0 ? item.total_roi.toFixed(2) : item.total_roi, item.total_staking])
        });
        doc.save('backup_history.pdf');
    };
    
    return (

        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="fa fa-wave-square" ></i></div>
                                        Backup History
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="filter_bar" >
                    </div>
                    <div className="card mb-4">
                        <div className="card-header">
                            <input className="form-control w-50" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                            <div className="dropdown">
                                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                    <CSVLink data={backupHistory} className="dropdown-item">Export as CSV</CSVLink>
                                    <button onClick={exportAsPdf} className="dropdown-item">Export as PDF</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable columns={columns} data={backupHistory} pagination />

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>

    )
}

export default BackupData;