import React, { useState } from "react";
import { Link } from "react-router-dom";


const Sidebar = () => {

    const [activeTab, setActiveTab] = useState('');
    const myPermission = sessionStorage.getItem('permissions');
    const userType = sessionStorage.getItem('admin_type');
    let permissions = Array.isArray(JSON.parse(myPermission)) ? JSON.parse(myPermission)?.map(x => x.value) : [];



    return (
        <>
            <div id="layoutSidenav_nav">
                <nav className="sidenav shadow-right sidenav-light">
                    <div className="sidenav-menu">
                        <div className="nav accordion" id="accordionSidenav">
                            <div className="sidenav-menu-heading">pages</div>

                            <Link to="dashboard" className={`nav-link collapsed ${activeTab?.includes('dashboard') ? 'active' : ''}`} onClick={() => { setActiveTab('dashboard'); document.body.classList.remove('sidenav-toggled') }}>
                                <div className="nav-link-icon"><i className="fa fa-th"></i></div>
                                Dashboards
                            </Link>


                            {userType === '1' ?
                                <Link className={`nav-link collapsed ${activeTab?.includes('subadmin') ? 'active' : ''}`} to="subadmin" onClick={() => { setActiveTab('subadmin'); document.body.classList.remove('sidenav-toggled') }}>
                                    <div className="nav-link-icon"><i className="fa-solid fa-user-tie"></i></div>
                                    Sub Admin
                                </Link>
                                : null
                            }
                            {permissions.includes(1) || userType === '1' ?
                                <Link className={`nav-link collapsed ${activeTab?.includes('user_list') ? 'active' : ''}`} to="user_list" onClick={() => { setActiveTab('user_list'); document.body.classList.remove('sidenav-toggled') }}>
                                    <div className="nav-link-icon"><i className="fa fa-user-friends"></i></div>
                                    User List
                                </Link>
                                : null
                            }

                            {permissions.includes(2) || userType === '1' ?
                                <>
                                    <div className={`nav-link collapsed ${(activeTab?.includes('user_stackingHistory') || activeTab?.includes('admin_stackingHistory')) || activeTab?.includes('funds_transfer') ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseStackingDetails" aria-expanded="false" aria-controls="collapseSubadmin">
                                        <div className="nav-link-icon"><i className="fa fa-chart-bar"></i></div>
                                        Transaction History
                                        <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                    </div>

                                    <div className="collapse" id="collapseStackingDetails" data-bs-parent="#accordionSidenav">
                                        <nav className="sidenav-menu-nested nav">
                                            <Link className={`nav-link  ${activeTab?.includes('user_stackingHistory') ? 'active' : ''}`} to="user_stackingHistory" onClick={() => { setActiveTab('user_stackingHistory'); document.body.classList.remove('sidenav-toggled') }}>User Stacking History</Link>

                                            <Link className={`nav-link  ${activeTab?.includes('admin_stackingHistory') ? 'active' : ''}`} to="admin_stackingHistory" onClick={() => { setActiveTab('admin_stackingHistory'); document.body.classList.remove('sidenav-toggled') }}>Admin Stacking History</Link>

                                            <Link className={`nav-link ${activeTab?.includes('funds_transfer') ? 'active' : ''}`} to="funds_transfer" onClick={() => { setActiveTab('funds_transfer'); document.body.classList.remove('sidenav-toggled') }}>Funds Transfer History</Link>
                                        </nav>
                                    </div>
                                </>
                                : null
                            }

                            {permissions.includes(4) || userType === '1' ?
                                <>
                                    <div className={`nav-link collapsed ${(activeTab?.includes('funds_deposit') || activeTab?.includes('funds_withdrawal')) ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseFundsManagement" aria-expanded="false" aria-controls="collapseSubadmin">
                                        <div className="nav-link-icon"><i className="fa fa-dollar-sign"></i></div>
                                        Funds Management
                                        <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                    </div>

                                    <div className="collapse" id="collapseFundsManagement" data-bs-parent="#accordionSidenav">
                                        <nav className="sidenav-menu-nested nav">
                                            <Link className={`nav-link  ${activeTab?.includes('funds_deposit') ? 'active' : ''}`} to="funds_deposit" onClick={() => { setActiveTab('funds_deposit'); document.body.classList.remove('sidenav-toggled') }}>Deposit List</Link>
                                            <Link className={`nav-link  ${activeTab?.includes('funds_withdrawal') ? 'active' : ''}`} to="funds_withdrawal" onClick={() => { setActiveTab('funds_withdrawal'); document.body.classList.remove('sidenav-toggled') }}>Withdrawal List</Link>
                                        </nav>
                                    </div>
                                </>
                                : null
                            }

                            {permissions.includes(5) || userType === '1' ?
                                <Link className={`nav-link collapsed ${activeTab?.includes('notifications_management') ? 'active' : ''}`} to="notifications_management" onClick={() => { setActiveTab('notifications_management'); document.body.classList.remove('sidenav-toggled') }}>
                                    <div className="nav-link-icon"><i className="fa fa-bell"></i></div>
                                    Notifications
                                </Link>
                                : null
                            }

                            {permissions.includes(6) || userType === '1' ?
                                <Link className={`nav-link collapsed ${activeTab?.includes('classic_news') ? 'active' : ''}`} to="classic_news" onClick={() => { setActiveTab('classic_news'); document.body.classList.remove('sidenav-toggled') }}>
                                    <div className="nav-link-icon"><i className="fa-solid fa-newspaper"></i></div>
                                    Classic News
                                </Link>
                                : null
                            }
                            {permissions.includes(7) || userType === '1' ?
                                <Link className={`nav-link collapsed ${activeTab?.includes('user_backup') ? 'active' : ''}`} to="user_backup" onClick={() => { setActiveTab('user_backup'); document.body.classList.remove('sidenav-toggled') }}>
                                    <div className="nav-link-icon"><i className="fa-solid fa-file-invoice"></i></div>
                                    Backup History
                                </Link>
                                : null
                            }


                        </div>
                    </div>
                </nav >
            </div >
        </>
    );
};

export default Sidebar;