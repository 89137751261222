const appUrl = "https://backend.carnivalcoin.io";

export const ApiConfig = {
  // =========EndPoints==========
  login: "login",
  dashboard: 'dashboard',
  addSubAdmin: "add-new-admin",
  getuserlist: "user-list",
  updateUserRank:"/update-user-rank",
  subadminlist: "admin-list",
  setuserstatus: "update-user-status",
  getDetails: "get-user-details",
  stackinghistory: "stacking-history",
  adminStackingHistory: "admin-stacking-transfer-history",
  adminFundsHistory: "admin-fund-transfer-history",
  addFunds: "add-funds",
  deductFunds: "deduct-funds",
  fundsAddStake: "add-user-stacking",
  fundsDeductStake: "deduct-user-stacking",
  subadmindelete: "delete-subadmin",
  updateSubadmin: "update-subadmin",
  SubadminStatus: "update-admin-status",
  notificationList: "get-all-notification",
  withdrawalList: "all-withdrawals",
  depositList: "all-deposits",
  addNotify: "create-notification",
  deleteNotify: "delete-notification",
  withdrawalStatus: "update-withdrawal-status",
  depositStatus: "update-deposit-status",
  getNewsList:"get-all-classic-news",
  addClassicNews:'create-classic-news',
  deleteClassicNews:"delete-classic-news",
  backupHistory:"all-user-backup",
  

  // ============URLs================
  verifyadmin: `${appUrl}/v2/admin/`,
  stackingDetails: `${appUrl}/v2/stake/`,
  baseUrl: `${appUrl}/`,
};
