import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import UserDetails from "../UserDetails";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTable from "react-data-table-component";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";


const UserList = () => {

    const [activeScreen, setActiveScreen] = useState('userdetail');
    const [data, setData] = useState([]);
    const [userId, setUserId] = useState('');
    const [userData, setUserData] = useState();
    const [filterData, setFilterData] = useState([]);

    useEffect(() => {
        handleData();
    }, []);

    const columns = [
        { name: 'Sr No.', selector: (row, index) => row?.index, sort: true, wrap: true },
        { name: "Created At", sort: true, wrap: true, selector: row => moment(row?.createdAt).format('DD/MM/YY') },
        { name: "Name", sort: true, wrap: true, selector: row => row?.name, },
        { name: "User Id", sort: true, wrap: true, selector: row => row?.user_id, },
        { name: "Email", sort: true, wrap: true, selector: row => row?.email, },
        { name: "Phone", sort: true, wrap: true, selector: row => row?.phone, },
        {
            name: "Profile Pic", sort: true, wrap: true,
            selector: row =>
                row?.profilepicture ? (
                    <img className="img-fluid w-50" src={ApiConfig.baseUrl + "/" + row?.profilepicture} alt="" />
                ) : (
                    <img
                        className="img-fluid w-50"
                        src="assets/img/illustrations/profiles/profile-5.png"
                        alt="profilePicture"
                    />)
        },
        { name: <div>Current Rank</div>, sort: true, wrap: true, selector: row => row?.current_rank, },
        {
            name: "Status", sort: true, wrap: false, selector: row => (
                <button
                    className={row?.loginStatus === "ACTIVE" ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"} onClick={() => handleStatus(row?.user_id, row?.loginStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE")}>{row?.loginStatus}</button>
            ),
        },
        {
            name: 'Actions', sort: true, wrap: true, selector: row => (
                <div>
                    <button
                        className="btn btn-orange btn-sm my-2"
                        title="View"
                        onClick={() => { setUserData(row); setUserId(row?._id); setActiveScreen('detail'); }}><i className="fa-solid fa-eye"></i></button>
                </div>)
        }
    ];

    const handleData = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.getusers();
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                let filterData = result?.data.reverse().map((item, index) => ({ ...item, index: index + 1, id: index + 1 }))
                setFilterData(filterData);
                setData(filterData);
                // alertSuccessMessage(result?.message);
            } else {
                alertErrorMessage(result?.message);
            };
        }
        finally { LoaderHelper.loaderStatus(false) };
    };

    const handleStatus = async (user_id, status) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.handleUserListStatus(user_id, status);
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage(result?.message)
                handleData();
            } else {
                alertErrorMessage(result?.message);
            };
        }
        finally { LoaderHelper.loaderStatus(false) };
    };

    const searchObjects = (e) => {
        const keysToSearch = ["name", "email", "phone", "user_id"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = filterData.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setData(matchingObjects);
    };

    return (
        activeScreen === 'userdetail' ?
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="fa fa-wave-square" ></i></div>
                                            User List
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="filter_bar" >
                        </div>
                        <div className="card mb-4">
                            <div className="card-header">
                                <input className="form-control w-50" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                                <div className="dropdown">
                                    <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                    <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                        <CSVLink data={data} className="dropdown-item">Export as CSV</CSVLink>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable columns={columns} data={data} pagination />

                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            :
            <UserDetails userId={userId} userData={userData} />
    )
}

export default UserList;