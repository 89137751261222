import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import UserList from "../UserList";
import { CSVLink } from "react-csv";
import { $ } from "react-jquery-plugin";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";



const UserDetails = (props) => {

  const User_ID = props?.userData.user_id
  console.log('id', User_ID);
  


  const [activeScreen, setActiveScreen] = useState("userList");
  const [rank, setRank] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [cctBalance, setCctBalance] = useState([]);
  const [balance, setBalance] = useState([]);
  const [totalStacking, setTotalStacking] = useState([]);
  const [amount, setAmount] = useState();


  useEffect(() => {
    handleUserData(User_ID);
  }, []);


  console.log('userDetauls',userDetails)

  const handleUserData = async (User_ID) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.allUsersData(User_ID);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        setUserDetails(result?.data)
        setCctBalance(result?.data?.wallet?.cct_balance);
        setBalance(result?.data?.wallet?.balance);
        setTotalStacking(result?.data.total_staking);
        setRank(result?.data.current_rank);
      } else {
        alertErrorMessage(result?.message);
      }
    }
    finally { LoaderHelper.loaderStatus(false) };
  };


  const handleUserFundsAdd = async (amount, User_ID) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.addFunds(amount, User_ID);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        $("#funds_modal").modal("hide");
        alertSuccessMessage(result.message);
        handleUserData(User_ID);
        setAmount('');
      } else {
        alertErrorMessage(result?.message);
      }
    }
    finally { LoaderHelper.loaderStatus(false) };
  };


  const showTransfer = () => {
    $("#funds_modal").modal("show");
  };

  const showTransferDeduct = () => {
    $("#funds_deduct_modal").modal("show");
  };

  const addStakeFunds = () => {
    $("#add_stake_modal").modal("show");
  };

  const deductStakeFunds = () => {
    $("#deduct_stake_modal").modal("show");
  };

  const handleUserFundsDeduct = async (amount, User_ID) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.fundsDeduct(amount, User_ID);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        $("#funds_deduct_modal").modal("hide");
        alertSuccessMessage(result.message);
        handleUserData(User_ID);
        setAmount('');
      } else {
        alertErrorMessage(result?.message);
      }
    }
    finally { LoaderHelper.loaderStatus(false) }

  };

  const handleAddStakeAmount = async (User_ID, amount, phase) => {
    LoaderHelper.loaderStatus(true);
    try {
      let amountNum = parseInt(amount);
      const result = await AuthService.fundsAddStake(User_ID, amountNum, phase);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        $("#add_stake_modal").modal("hide");
        alertSuccessMessage(result.message);
        handleUserData(User_ID);
        setAmount('');

        alertSuccessMessage(result?.message);
      } else {
        alertErrorMessage(result?.message);
      }
    }
    finally { LoaderHelper.loaderStatus(false) };
  };


  const handleDeductStakeAmount = async (User_ID, amount, phase) => {
    LoaderHelper.loaderStatus(true);
    try {
      let amountNum = parseInt(amount);
      const result = await AuthService.fundsDeductStake(User_ID, amountNum, phase);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        $("#deduct_stake_modal").modal("hide");
        alertSuccessMessage(result?.message);
        handleUserData(User_ID);
        setAmount('');
      } else {
        alertErrorMessage(result?.message);
      }

    }
    finally { LoaderHelper.loaderStatus(false) };

  };

  const handleUpdateRank = async (id, rank) => {
    // console.log('id & rank', id, rank)
    // return
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.updateUserRank(id, rank);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        handleUserData(User_ID);
        alertSuccessMessage(result?.message);
      } else {
        alertErrorMessage(result?.message);
      }
    }
    finally { LoaderHelper.loaderStatus(false) };
  }

  return activeScreen === "userList" ? (
    <>
      <div id="layoutSidenav_content">
        <main>
          <form className="form-data">
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
              <div className="container-xl px-4">
                <div className="page-header-content pt-4">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto mt-4">
                      <h1 className="page-header-title">
                        <Link
                          to="/user_list"
                          className="page-header-icon"
                          onClick={() => setActiveScreen("support")}
                        >
                          <i className="fa fa-arrow-left"></i>
                        </Link>
                        {userDetails.name}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="container-xl px-4 mt-n10">
              <div className="row">
                <div className="col-xl-4">
                  <div className="card mb-4 mb-xl-0">
                    <div className="card-body py-5">
                      {/* <!-- Profile picture image--> */}
                      <div className="text-center">
                        {!userDetails.profilepicture ? (
                          <img
                            className="img-account-profile rounded-circle mb-4"
                            src="assets/img/illustrations/profiles/profile-5.png"
                            alt="profilePicture"
                          />
                        ) : (
                          <img
                            className="img-account-profile rounded-circle mb-4"
                            src={ApiConfig?.baseUrl + userDetails.profilepicture}
                            alt="Selfie"
                          />
                        )}

                        <h3 className="fw-bolder fs-2 mb-0">{userDetails.name}</h3>
                        <div className=" text-gray">
                          <small>{userDetails.email}</small>
                        </div>
                      </div>
                      <div className=" py-5 px-4 ">
                        <ul
                          className="nav nav-pills flex-column"
                          id="cardTab"
                          role="tablist"
                        >
                          <li className="nav-item  ">
                            <a
                              className="menu-link d-flex nav-link active"
                              id="personal-pill"
                              href="#personalPill"
                              data-bs-toggle="tab"
                              role="tab"
                              aria-controls="personal"
                              aria-selected="true"
                            >
                              <span className="menu-bullet">
                                <span className="bullet"></span>
                              </span>
                              <span className="menu-title">
                                {" "}
                                Personal Information{" "}
                              </span>
                            </a>
                          </li>
                          <li className="nav-item ">
                            <a
                              className="menu-link d-flex nav-link"
                              id="Transitions-pill"
                              href="#TransitionsPill"
                              data-bs-toggle="tab"
                              role="tab"
                              aria-controls="example"
                              aria-selected="false"
                            >
                              {" "}
                              <span className="menu-bullet">
                                <span className="bullet"></span>
                              </span>
                              <span
                                className="menu-title"
                              // onClick={() => handleFundData()}
                              >
                                {" "}
                                Funds{" "}
                              </span>
                            </a>
                          </li>

                          <li className="nav-item ">
                            <a
                              className="menu-link d-flex nav-link"
                              id="Transitions-pill"
                              href="#leftRight"
                              data-bs-toggle="tab"
                              role="tab"
                              aria-controls="example"
                              aria-selected="false"
                            >
                              {" "}
                              <span className="menu-bullet">
                                <span className="bullet"></span>
                              </span>
                              <span
                                className="menu-title"

                              >
                                {" "}
                                Rank Update{" "}
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div
                    className="tab-content tab-content-card"
                    id="cardPillContent"
                  >
                    <div
                      className="tab-pane card show active"
                      id="personalPill"
                      role="tabpanel"
                      aria-labelledby="personal-pill"
                    >
                      <div className="card-header">Personal Information</div>
                      <div className="card-body">
                        <div className="profile_data py-4 px-4">
                          <div className="row">
                            {" "}
                            <span className="col-3">User Id: </span>
                            <strong className="col text-dark ">
                              {userDetails.user_id}
                            </strong>
                          </div>
                          <hr className="my-3" />
                          <div className="row">
                            {" "}
                            <span className="col-3">Name: </span>
                            <strong className="col text-dark ">
                              {userDetails.name}
                            </strong>
                          </div>
                          <hr className="my-3" />
                          <div className="row">
                            {" "}
                            <span className="col-3">Email:</span>{" "}
                            <strong className=" col">{userDetails.email}</strong>
                          </div>
                          <hr className="my-3" />
                          <div className="row">
                            {" "}
                            <span className="col-3">Mobile Number:</span>{" "}
                            <strong className="col"> {userDetails.phone} </strong>
                          </div>
                          <hr className="my-3" />
                          <div className="row">
                            {" "}
                            <span className="col-3">Refer Code:</span>{" "}
                            <strong className=" col"> {''} </strong>
                          </div>
                          <hr className="my-3" />
                          <div className="row">
                            {" "}
                            <span className="col-3 ">Status:</span>{" "}
                            <strong className="col"> {userDetails.loginStatus} </strong>
                          </div>
                          <hr className="my-3" />
                          <div className="row">
                            {" "}
                            <span className="col-3">Current Rank:</span>{" "}
                            <strong className=" col"> {rank} </strong>
                          </div>
                          <hr className="my-3" />
                          <div className="row">
                            {" "}
                            <span className="col-3">Stacking Status:</span>{" "}
                            <strong className=" col"> {userDetails.staking_status} </strong>
                          </div>
                          <hr className="my-3" />
                        </div>
                      </div>
                    </div>

                    {/* User Transitions*/}
                    <div
                      className="tab-pane card"
                      id="TransitionsPill"
                      role="tabpanel"
                      aria-labelledby="Transitions-pill"
                    >
                      <div
                        className="tab-pane card show active"
                        id="personalPill"
                        role="tabpanel"
                        aria-labelledby="personal-pill"
                      >
                        <div className="card-header d-flex align-items-center justify-space-between">
                          <div>Funds</div>
                          <div className=" d-flex" style={{ columnGap: "20px" }}>

                            <button
                              className="btn btn-sm btn-primary"
                              type="button"
                              onClick={() => showTransfer()}
                            >
                              Add Funds
                            </button>
                            <button
                              className="btn btn-sm btn-primary"
                              type="button"
                              onClick={() => showTransferDeduct()}
                            >
                              Deduct Funds
                            </button>
                            <button
                              className="btn btn-sm btn-primary"
                              type="button"
                              onClick={() => addStakeFunds()}
                            >
                              Add Stake
                            </button>
                            <button
                              className="btn btn-sm btn-primary"
                              type="button"
                              onClick={() => deductStakeFunds()}
                            >
                              Deduct Stake
                            </button>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="profile_data py-4 px-4">
                            <div className="row">
                              <strong className="col"> Wallet Type </strong>
                              <strong className="col text-dark ">
                                Balance{" "}
                              </strong>
                            </div>
                            <hr className="my-3" />
                            <div className="row">
                              <span className="col">CCT Balance : </span>
                              <span className="col text-dark ">
                                {cctBalance}
                              </span>
                            </div>
                            <hr className="my-3" />
                            <div className="row">
                              <span className="col">Balance :</span>
                              <span className=" col">{balance}</span>
                            </div>
                            <hr className="my-3" />
                            <div className="row">
                              <span className="col">Total Stacking :</span>
                              <span className=" col">{totalStacking}</span>
                            </div>
                            <hr className="my-3" />

                          </div>
                        </div>
                      </div>
                    </div>

                    {/* leftRight Transitions*/}
                    <div
                      className="tab-pane card"
                      id="leftRight"
                      role="tabpanel"
                      aria-labelledby="Transitions-pill"
                    >
                      <div className="card-header">
                        Update Rank
                        {/* {rank.length === 0 ? (
                          ""
                        ) : (
                          <div className="dropdown">
                            <button
                              className="btn btn-dark btn-sm dropdown-toggle"
                              id="dropdownFadeInUp"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Export
                            </button>
                            <div
                              className="dropdown-menu animated--fade-in-up"
                              aria-labelledby="dropdownFadeInUp"
                            >
                              <CSVLink data={rank} className="dropdown-item">
                                Expoprt as CSV
                              </CSVLink>
                            </div>
                          </div>
                        )} */}
                      </div>
                      <>
                        {/* {rank.length === 0 ? (
                          <h6 className="ifnoData">
                            <img src="assets/img/no-data.png" /> <br /> No Data
                            Available
                          </h6>
                        ) : ( */}
                          <div className="card-body">
                            <form className="row">
                              <div className="col-12 py-4">

                                <div className="row">
                                  <strong className="col"><select className="border border-gray rounded p-1" value={rank} onChange={(e)=>setRank(e.target.value)}>
                                    <option value="BEGINNER">BEGINNER</option>
                                    <option value="RUNNER">RUNNER</option>
                                    <option value="STAR">STAR</option>
                                    <option value="DIAMOND">DIAMOND</option>
                                    <option value="EMERALD">EMERALD</option>
                                    <option value="GOLD">GOLD</option>
                                    <option value="RUBY">RUBY</option>
                                    <option value="SAPPHIRE">SAPPHIRE</option>
                                    <option value="STARDIAMOND">STARDIAMOND</option>
                                    </select></strong>
                                  <span className=" col"><button className="btn btn-indigo fw-bolder" type="button" onClick={()=>handleUpdateRank(User_ID, rank)}>Update Rank</button></span>
                                </div>
                                <hr className="my-3" />

                              </div>
                            </form>
                          </div>
                      </>
                    </div>

                    {/* User Transitions*/}
                    <div
                      className="tab-pane card"
                      id="userReports"
                      role="tabpanel"
                      aria-labelledby="Transitions-pill"
                    >

                      <>

                        <div className="card-body">
                          <form className="row">
                            <div className="col-12">
                              <table className="" width="100%">

                              </table>
                            </div>
                          </form>
                        </div>

                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </main>
      </div>

      {/* alert modal data */}
      <div
        className="modal"
        id="rejectmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered alert_modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                {" "}
                Are You Sure ?
              </h5>
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group mb-4 ">
                  <label className="small mb-1"> Enter Reason </label>
                  <textarea
                    className="form-control form-control-solid"
                    rows="7"
                    Placeholder="Enter your message.."
                  ></textarea>
                </div>
                <button
                  className="btn btn-danger btn-block w-100"
                  data-bs-dismiss="modal"
                  type="button"
                >
                  Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* alert modal data */}


      {/* funds modal */}
      <div className="modal" id="funds_modal" tabIndex="-1" role="dialog" aria-labelledby="funds_modal_modalTitle" aria-hidden="true">
        <div className="modal-dialog  alert_modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle"> Add Funds</h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Amount </label>
                  <input className="form-control  form-control-solid input-copy" type="number" onWheelCapture={e => {
                    e.target.blur()
                  }} Placeholder="Enter Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                </div>
                <div className="form-group  mb-3 position-relative">
                  <button className="btn btn-primary btn-block w-100" type="button" onClick={() => handleUserFundsAdd(amount, User_ID)} >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="funds_deduct_modal" tabIndex="-1" role="dialog" aria-labelledby="funds_deduct_modal_modalTitle" aria-hidden="true">
        <div className="modal-dialog  alert_modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">Deduct Funds</h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Amount </label>
                  <input className="form-control  form-control-solid input-copy" type="number" onWheelCapture={e => {
                    e.target.blur()
                  }} Placeholder="Enter Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                </div>
                <div className="form-group  mb-3 position-relative">
                  <button className="btn btn-primary btn-block w-100" type="button" onClick={() => handleUserFundsDeduct(amount, User_ID)} >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* stake modal */}
      <div className="modal" id="add_stake_modal" tabIndex="-1" role="dialog" aria-labelledby="stake_modal_modalTitle" aria-hidden="true">
        <div className="modal-dialog  alert_modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">Stake</h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Amount </label>
                  <input className="form-control  form-control-solid input-copy mb-3" type="number" onWheelCapture={e => {
                    e.target.blur()
                  }} Placeholder="Enter Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />

                </div>
                <div className="form-group  mb-3 position-relative">
                  <button className="btn btn-primary btn-block w-100" type="button" onClick={() => handleAddStakeAmount(User_ID, amount, 'Phase 2')} >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="deduct_stake_modal" tabIndex="-1" role="dialog" aria-labelledby="stake_modal_modalTitle" aria-hidden="true">
        <div className="modal-dialog  alert_modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">Stake</h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Amount </label>
                  <input className="form-control  form-control-solid input-copy mb-3" type="number" onWheelCapture={e => {
                    e.target.blur()
                  }} Placeholder="Enter Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />

                </div>
                <div className="form-group  mb-3 position-relative">
                  <button className="btn btn-primary btn-block w-100" type="button" onClick={() => handleDeductStakeAmount(User_ID, amount, 'Phase 2')} >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


    </>
  ) : (
    <UserList />
  );
};

export default UserDetails;
