import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../../customComponent/CustomAlertMessage";
import Select from "react-select";
import { CSVLink } from "react-csv";
import moment from "moment";
import DataTable from "react-data-table-component";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { $ } from "react-jquery-plugin";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import copy from "copy-to-clipboard";

const DepositList = () => {
  const [fundDepositList, setFundDepositList] = useState([]);
  const [status, setStatus] = useState("");
  const [created, setCreated] = useState("");
  const [userID, setUserID] = useState("");
  const [currency, setCurrency] = useState("");
  const [type, setType] = useState("");
  const [id, setId] = useState("");
  const [transaction_hash, setTransaction_hash] = useState("");
  const [chain, setChain] = useState("");
  const [depositSlip, setDepositSlip] = useState("");

  const handleDepositList = async (status) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.getDepositList(status);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        let filteredData = result?.data
          .reverse()
          .map((item, index) => ({ ...item, index: index + 1, id: index + 1 }));
        setFundDepositList(filteredData);
        // alertSuccessMessage(result.message);
      } else {
        alertErrorMessage(result.message);
      }
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };

  useEffect(() => {
    handleDepositList(status);
  }, []);

  const multipleSelect = [
    { value: "Select", label: "" },
    { value: "PENDING", label: "Pending" },
    { value: "REJECTED", label: "Rejected" },
    { value: "COMPLETED", label: "Completed" },
  ];

  const columns = [
    {
      name: "Sr No.",
      selector: (row, index) => row?.index,
      sort: true,
      wrap: true,
    },
    {
      name: "Created At",
      selector: (row) => moment(row?.createdAt).format("DD/MM/YY || LT"),
      wrap: true,
      sort: true,
    },
    { name: "Name", selector: (row) => row?.user_name, wrap: true, sort: true },
    {
      name: "User ID",
      selector: (row) => row?.user_id,
      wrap: true,
      sort: true,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
      wrap: true,
      sort: true,
    },
    { name: "Amount", selector: (row) => row?.amount, wrap: true, sort: true },
    {
      name: "Status",
      selector: (row) => row?.status,
      cell: (row) => (
        <span
          className="fw-bolder"
          style={{
            color:
              row.status === "PENDING"
                ? "orange"
                : row.status === "REJECTED"
                ? "red"
                : "green",
          }}
        >
          {row?.status}
        </span>
      ),
      wrap: true,
      sort: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <button
          className="btn btn-dark btn-sm me-2"
          title="View"
          onClick={() => {
            $("#edit_modal").modal("show");
            setCreated(row?.createdAt);
            setCurrency(row?.currency);
            setStatus(row?.status);
            setType(row?.type);
            setUserID(row?.user_id);
            setId(row?._id);
            setTransaction_hash(row?.transaction_hash);
            setChain(row?.chain);
            setDepositSlip(row?.deposit_slip);
          }}
        >
          <i className="fa-solid fa-eye"></i>
        </button>
      ),
    },
  ];

  const handleDepositStatus = async (_id, status) => {
    try {
      const result = await AuthService.depositStatus(_id, status);
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        handleDepositList(status);
        $("#edit_modal").modal("hide");
      } else {
        alertErrorMessage(result.message);
      }
    } finally {
      LoaderHelper.loaderStatus(false);
    }
  };

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="mt-4 d-flex justify-content-between">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="fa fa-dollar-sign"></i>
                      </div>
                      Deposit List
                    </h1>
                    <div className="d-flex">
                      <>
                        <Select
                          options={multipleSelect}
                          onChange={(selectedOptions) => {
                            setStatus(selectedOptions.value);
                            handleDepositList(
                              selectedOptions.value
                                ? selectedOptions.value
                                : selectedOptions
                            );
                          }}
                          value={multipleSelect.find(
                            (option) => option.value === status
                          )}
                          className="text-dark"
                        ></Select>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          {/* Main page content */}
          <div className="container-xl px-4 mt-n10">
            <div className="card mb-4">
              <div className="card-header">
                Deposit Management
                {fundDepositList.length === 0 ? (
                  ""
                ) : (
                  <div className="dropdown">
                    <button
                      className="btn btn-dark btn-sm dropdown-toggle"
                      id="dropdownFadeInUp"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Export{" "}
                    </button>
                    <div
                      className="dropdown-menu animated--fade-in-up"
                      aria-labelledby="dropdownFadeInUp"
                    >
                      <CSVLink data={fundDepositList} className="dropdown-item">
                        Expoprt as CSV
                      </CSVLink>
                    </div>
                  </div>
                )}
              </div>
              <>
                {fundDepositList.length === 0 ? (
                  <h6 className="ifnoData">
                    <img src="assets/img/no-data.png" /> <br /> No Data
                    Available
                  </h6>
                ) : (
                  <div className="card-body mt-3">
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={fundDepositList}
                        pagination
                      />
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </main>
      </div>

      {/* Edit Modal */}

      <div
        className="modal"
        id="edit_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog  alert_modal modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Deposit Details
              </h5>
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row gx-3 mb-3">
                  <div className="col-md-3">
                    <label className="small mb-1" htmlFor="inputCreated">
                      Created At
                    </label>
                    <input
                      className="form-control  form-control-solid"
                      id="inputCreated"
                      type="text"
                      value={moment(created).format("DD/MM/YY LT")}
                      disabled
                    />
                  </div>
                  <div className="col-md-9">
                    <label
                      className="small mb-1"
                      htmlFor="inputTransactionHash"
                    >
                      Transaction Hash{" "}
                    </label>
                    <div className="input-group">
                      <input
                        className="form-control form-control-solid"
                        id="inputTransactionHash"
                        type="text"
                        value={transaction_hash}
                        disabled
                      />
                      <span className="input-group-text form-control-solid">
                        <i
                          className="fa-solid fa-clipboard"
                          type="button"
                          title="Copy"
                          onClick={() => {
                            copy(transaction_hash);
                            alertSuccessMessage("Copied");
                          }}
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row gx-3 mb-3">
                  <div className="col-md-3">
                    <label className="small mb-1" htmlFor="inputUserID">
                      User ID
                    </label>
                    <input
                      className="form-control form-control-solid"
                      id="inputUserID"
                      type="text"
                      value={userID}
                      disabled
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="small mb-1" htmlFor="inputCurrency">
                      Currency
                    </label>
                    <input
                      className="form-control form-control-solid"
                      id="inputCurrency"
                      type="text"
                      value={currency}
                      disabled
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="small mb-1" htmlFor="inputChain">
                      Chain
                    </label>
                    <input
                      className="form-control form-control-solid"
                      id="inputChain"
                      type="text"
                      value={chain}
                      disabled
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="small mb-1" htmlFor="inputType">
                      Type
                    </label>
                    <input
                      className="form-control form-control-solid"
                      id="inputType"
                      type="text"
                      value={type}
                      disabled
                    />
                  </div>
                </div>
                <div className="row gx-3 mb-3">
                  <div className="col-md-5">
                    <label className="small mb-1" htmlFor="inputStatus">
                      Status
                    </label>
                    <input
                      className="form-control form-control-solid"
                      id="inputStatus"
                      type="text"
                      value={status}
                      disabled
                    />
                  </div>
                  <div className="col-md-8 w-25">
                    <label className="small mb-1" htmlFor="inputDepositSlip">
                      Deposit Slip
                    </label>

                    {/* <span className="input-group-text form-control-solid"> */}
                    <a
                      className=""
                      target="_blank"
                      href={ApiConfig?.baseUrl + "uploads/" + depositSlip}
                    >
                      <img
                        className="w-50 input-group-text form-control-solid"
                        src={ApiConfig?.baseUrl + "uploads/" + depositSlip}
                      />
                    </a>
                    {/* </span> */}
                  </div>
                </div>
                {status === "COMPLETED" || status === "REJECTED" ? (
                  <div
                    className={
                      status === "COMPLETED"
                        ? "text-success fw-bolder mt-5"
                        : "text-danger fw-bolder mt-5"
                    }
                  >
                    The status is {status}
                  </div>
                ) : (
                  <>
                    <button
                      className="btn btn-success me-3 mt-3"
                      type="button"
                      onClick={() => {
                        handleDepositStatus(id, "COMPLETED");
                      }}
                    >
                      {" "}
                      Approve{" "}
                    </button>
                    <button
                      className="btn btn-danger ms-3 mt-3"
                      type="button"
                      onClick={() => {
                        handleDepositStatus(id, "REJECTED");
                      }}
                    >
                      {" "}
                      Reject{" "}
                    </button>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositList;
